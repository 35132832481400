<template>
  <b-modal
    id="refferalCompany-edit-modal"
    title="Referral Company Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Referral Company Create</h2>
    </template>
    <validation-observer ref="referralCompanyEditFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="name">
          <template #label> Name <span class="text-danger">*</span> </template>
          <b-form-input
            id="name"
            v-model="name"
            placeholder="Enter the name"
            required
            @keydown="checkMaxLength($event, 'name', 400)"
          />
        </b-form-group>
        <b-form-group label-for="companyName">
          <template #label>
            Company Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="companyName"
            v-model="companyName"
            placeholder="Enter Company name"
            required
            @keydown="checkMaxLength($event, 'name', 400)"
          />
        </b-form-group>
        <b-form-group label-for="referenceNumber">
          <template #label>
            Reference Number <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Reference Number"
            rules="required|integer"
          >
            <b-form-input
              id="referenceNumber"
              v-model="referenceNumber"
              :state="errors.length > 0 ? false : null"
              name="referenceNumber"
              placeholder="Reference Number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="primary" pill class="mr-1">
            Save
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            pill
          >
            Reset
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  props: {
    referralCompany: Object,
  },
  data() {
    return {
      required,
      name: "",
      companyName: "",
      referenceNumber: "",
      status: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    if (this.referralCompany) {
      this.name = this.referralCompany.name;
      this.companyName = this.referralCompany.company_name;
      this.referenceNumber = this.referralCompany.referral_code;
      this.status = this.referralCompany.status;
    }
  },
  methods: {
    ...mapActions({
      updateRefferalCompany: "appData/updateRefferalCompany",
    }),
    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        event.target.value = null;
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity(
            "Please upload a PNG, JPG, or JPEG image file."
          );
          this.$refs.imageInput.$el.reportValidity();
        }
      } else {
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity("");
        }
      }
    },
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async validationForm() {
      const success =
        await this.$refs.referralCompanyEditFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("company_name", this.companyName);
        formData.append("referral_code", this.referenceNumber);
        formData.append("status", this.status);
        formData.append("updated_by", this.getLoggedInUser.id);
        const res = await this.updateRefferalCompany({
          payload: formData,
          pk: this.referralCompany.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Refferal Company updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("refferalCompany-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.companyName = "";
      this.referenceNumber = "";
      this.status = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style></style>
